import Cookies from 'js-cookie'

const key = 'token'
const openid = 'openId'

export function getToken() {
  return Cookies.get(key)
}

export function setToken(token) {
  return Cookies.set(key, token, { expires: 15 })
}

export function removeToken() {
  return Cookies.remove(key)
}

export function getOpenid() {
  return Cookies.get(openid)
}

export function setOpenid(id) {
  return Cookies.set(openid, id, { expires: 5 })
}