var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "tabbar" },
      [
        _c(
          "van-tabbar",
          {
            attrs: {
              "active-color": _vm.variables.theme,
              fixed: false,
              route: "",
            },
            model: {
              value: _vm.active,
              callback: function ($$v) {
                _vm.active = $$v
              },
              expression: "active",
            },
          },
          [
            _c(
              "van-tabbar-item",
              { attrs: { to: { name: "Home" }, icon: "wap-home" } },
              [_vm._v("首页")]
            ),
            _c(
              "van-tabbar-item",
              { attrs: { to: { name: "Category" }, icon: "bars" } },
              [_vm._v("分类")]
            ),
            _c(
              "van-tabbar-item",
              {
                attrs: {
                  to: { name: "Cart" },
                  icon: "shopping-cart",
                  badge: _vm.totalCartNum,
                },
              },
              [_vm._v("购物车")]
            ),
            _c(
              "van-tabbar-item",
              { attrs: { to: { name: "User" }, icon: "manager" } },
              [_vm._v("我的")]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", {
      staticClass: "tabbar--placeholder",
      staticStyle: { width: "100%", height: "50px" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }